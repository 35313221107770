import React from "react"
import { Link } from "gatsby"
import { SocialIcon } from 'react-social-icons'

const Social = () => (
  <div className="social-footer">
       <SocialIcon url="https://www.facebook.com/meekcode"  bgColor="#BABABA"  target="_blank" rel="noopener noreferrer" style={{ height: 28, width: 28, marginRight:4 }} />
       <SocialIcon url="https://twitter.com/meek_code"  bgColor="#BABABA"  target="_blank" rel="noopener noreferrer" style={{ height: 28, width: 28, marginRight:4 }} />
       <SocialIcon url="https://www.linkedin.com/company/meekcode"  bgColor="#BABABA"  target="_blank" rel="noopener noreferrer" style={{ height: 28, width: 28, marginRight:4 }} />
  </div>
)

const Footer = () => (
  <footer className="site-footer">
    <div className="left">
    <Link to="/privacy"><p className="left">Privacy Policy</p></Link>
    </div>
    <div className="center">
      <p>© {new Date().getFullYear()} MeekCode - All Rights Reserved</p>
    </div>
    <div className="social"><p>Follow Us</p> <Social/></div>
  </footer>
)

export default Footer