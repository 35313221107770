import React from "react"
import { Link, navigate } from "gatsby"
import { RiMenu3Line, RiCloseLine, RiSearchLine } from "react-icons/ri"

const MenuItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/blog",
    title: "Blog",
  },
  {
    path: "/apps",
    title: "Apps",
  },
  {
    path: "/about",
    title: "About",
  },
  {
    path: "/contact",
    title: "Contact",
  },
]

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }

    this.handleToggleClick = this.handleToggleClick.bind(this)
    this.onValid = this.onValid.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  onValid(e) {
    e.target.setCustomValidity("Search Field can't be empty")
  }

  render() {
    const listMenuItems = MenuItems.map((menuItem, index) => (
      <ListLink key={index} to={menuItem.path}>
        {menuItem.title}
      </ListLink>
    ))
    return (
      <nav className="site-navigation">
        <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}
        >
          <div className="icon-menu-line">
            <RiMenu3Line />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>
        <ul>
          {listMenuItems}
          <li>
            <form
              onSubmit={event => {
                event.preventDefault()
                let searchText = document.getElementById("searchText").value
                if (!!searchText) {
                  // navigate("/search-result/?q=dsad", {
                  //   state: { searchText },
                  // })
                  navigate(`/search-result?q=${searchText}`)
                }
              }}
            >
              <div className="searchBar">
                <input
                  type="text"
                  placeholder="Search.."
                  id="searchText"
                  // onInvalid={this.onValid}
                  // required
                ></input>
                <button
                  className="searchIcon"
                  type="submit"
                  onClick={() => {
                    let searchText = document.getElementById("searchText")
                      .value
                    if (!!searchText) {
                      // navigate("/search-result/", {
                      //   state: { searchText },
                      // })
                      navigate(`/search-result?q=${searchText}`)
                    }
                  }}
                >
                  <RiSearchLine size="24" color="white" style={{marginRight: "2px" }} />
                </button>
              </div>
            </form>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Navigation
